import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import type {
  WarehouseOptionParams,
  WarehousePositionBody,
  WarehousePositionPayload,
} from './modify-position.dto';
import { POSITION_INFO } from './modify-position.key';
import modifyPositionService from './modify-position.service';

/**
 * 获取仓库options
 * @param is_data_permissions 默认1:不按数据权限展示   2:按数据权限展示
 */
export const useWarehouseOptions = (params: WarehouseOptionParams) => {
  const { data, ...rest } = useQuery({
    queryKey: ['WAREHOUSE', 'OPTIONS'],
    queryFn: async () => {
      return modifyPositionService.getWarehouseOptions(params);
    },
  });

  const options = useMemo(() => {
    return data?.map((item) => ({
      value: item.id,
      label: item.title,
      ...item,
    }));
  }, [data]);

  return {
    ...rest,
    data,
    options,
  };
};

export const useGetModifyPosition = (params: WarehousePositionPayload) => {
  return useQuery({
    queryKey: POSITION_INFO(params),
    queryFn: async () => {
      return await modifyPositionService.getWarehousePositions(params);
    },
    enabled: false,
    cacheTime: 0,
  });
};

export const useModifyPosition = () => {
  return useMutation({
    mutationFn: async (body: WarehousePositionBody) => {
      await modifyPositionService.putWarehousePosition(body);
    },
  });
};
