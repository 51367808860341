export const JOB = ['JOB'];

export const GET_JOB = [...JOB];

export const GET_MODIFY_JOB = (payload: Record<string, any>) => [
  ...JOB,
  'modify',
  { ...payload },
];

// 为了记录用户上次选中的仓库为默认项，需要保存仓库 id 到 localStorage 中
// 任务修改仓位 - 仓库 id
export const JOB_WAREHOUSE_ID_KEY = 'JOB_WAREHOUSE_ID_KEY';
