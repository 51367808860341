import { ErrorBlock, SpinLoading } from 'antd-mobile';
import React from 'react';

interface Props<T = any> extends React.PropsWithChildren {
  list: T[];
  loading?: boolean;
  emptyTitle?: string;
}

export function ListContainer<T = any>(props: Props<T>) {
  if (props.loading) {
    return (
      <div className="pt-10 flex items-center justify-center">
        <SpinLoading />
      </div>
    );
  }

  if (props.list.length === 0) {
    return (
      <div className="py-4">
        <ErrorBlock
          status="empty"
          description=""
          title={props.emptyTitle ?? '暂无数据'}
        />
      </div>
    );
  }

  return <div>{props.children}</div>;
}
