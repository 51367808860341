import type { InputRef } from 'antd-mobile';

/**
 * 避免键盘弹出
 */
export const hideKeyboard = (inputRef: React.RefObject<InputRef>) => {
  inputRef.current?.nativeElement?.setAttribute('readonly', 'readonly');

  setTimeout(() => {
    inputRef.current?.nativeElement?.removeAttribute('readonly');
  }, 50);
};
