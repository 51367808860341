import { BooleanNumber } from '@erp-mobile/types';

// 采购单 item
export interface SignatureSkuDto {
  id: number;
  purchase_number: string; // "采购单"
  sku_id?: string;
  cover?: string; // "缩略图",
  sku_code?: string; // "SKU",
  title?: string; // "商品名称",
  warehouse_id?: string; // "仓库ID",
  warehouse_title?: string; // "仓库title",
  warehouse_positions_id?: string; // "仓位ID",
  warehouse_positions_title?: string; // "仓位title",
  warehousing_received_num?: number; // "已入库",
  purchase_quantity?: number; // "采购量",
  purchase_price?: number; // "采购金额",
  stock?: number; // "库存数",
  custom_text?: string; // "拓展属性",
  custom_text_code?: string;
  is_report_weight?: number; // 入库是否上报重量
  is_report_size?: number; /// 是否上报尺寸
  sign_quantity?: number; // 已签收数量
  purchase_order_item_id?: number; //采购 item_id
  is_merge?: BooleanNumber; // 是否为合并SKU
  /**
   * sku最大重量
   */
  weight_max?: number;
  /**
   * sku最小重量
   */
  weight_min?: number;
  /**
   * 合并SKU生成的调拨单，签收SKUID
   */
  target_sku_id?: string;
  /**
   * 合并SKU生成的调拨单，签收SKU CODE
   */
  target_sku_code?: string;
  /**
   * 预估重量
   */
  estimate_weight?: string;
  /**
   * 破损数量
   */
  bad_quantity?: number;
}

export interface SignaturePurchaseNumberDto {
  purchase_number: string; //  "采购单",
  desc?: string; //  "采购单备注",
  is_sticker?: BooleanNumber; //  "是否供应商贴标 1是 0否",
  type: SignatureType;
  /**
   * 消息：商品SKUxxx合并至xxx，请打印商品标签进行换标
   */
  msg?: string;
}

export enum SignatureType {
  采购入库 = 1,
  退货入库 = 2,
  手工入库 = 3,
  调拨入库 = 4,
}
// 签收入库单
export interface SignatureDto {
  number_list?: SignaturePurchaseNumberDto[];
  sign_skus?: SignatureSkuDto[];
}

// 获取签收入库列表 请求参数
export interface GetSignatureInPayload {
  sign_order?: string;
}

export interface MutationSignatureSkusReport {
  bad_quantity: number; // "不良品数量",
  bad_reason: string; // "不良品原因",
  images: string[]; // "不良品图片"
}
export interface MutationSignatureSkus {
  sku_code: string; // "商品SKU",
  weight: number; // "重量",
  sign_quantity: number; // "签收量",
  remark: string; // "仓库备注",
  long: number; // "长",
  width: number; // "宽",
  high: number; // "高",
  purchase_quantity: number; // "采购量"
  purchase_price: number;
  warehouse_id: number;
  purchase_order_item_id: number; //采购 item_id
  is_merge: BooleanNumber; // 是否为合并SKU
  warehouse_position_id: number;
  reports?: {
    reasons: MutationSignatureSkusReport[] | [];
    images: string[] | [];
  };
}

// 完成签收
export interface MutationSignaturePayload {
  /**
   * 任务码
   */
  task_code?: string;
  /**
   * 签收数据
   */
  sign_list: MutationSignatureList[];
  /**
   * 贴标 1正常贴标  2异常贴标
   */
  is_labeling: number;
  /**
   * 未贴标数量
   */
  no_labeling_num?: number;
  /**
   * 换标数量
   */
  transition_labeling_num?: number;
}

export interface MutationSignatureList {
  /**
   * 采购单号、调拨批次号
   */
  purchase_number: string;
  /**
   * 签收类型 入库类型 1采购入库 2退货入库 3手工入库 4调拨入库
   */
  type: SignatureType;
  skus: MutationSignatureSkus[];
}

// 质检报告 skus
export interface SignatureQualityReportSkuItemsDto {
  id: number | string;
  cover: string; // "缩略图",
  title: string; // "商品title",
  sku: string; // "SKU",
  sign_quantity: number; // "签收数量",
  purchase_quantity: number; // "采购数量",
  bad_quantity: number; // "不良品数量",
  bad_reason: number; // "不良品原因",
  result: number; // "质检结果 1合格   0不合格",
}

export interface SignatureQualityReportTableItems
  extends SignatureQualityReportSkuItemsDto {
  purchase_number?: string; // "采购单",
  sign_time_gmt?: string; // "签收时间",
  report_time_gmt?: string; // "质检时间",
  signature_type?: string;
  id: string;
}

export interface SignatureQualityReportItems {
  purchase_number: string; // "采购单",
  sign_time_gmt: string; // "签收时间",
  report_time_gmt: string; // "质检时间",
  skus: SignatureQualityReportSkuItemsDto[];
}

// 质检报告
export interface SignatureQualityReportDto {
  data: SignatureQualityReportItems[];
  reasons: {
    id: number;
    sku_code: string;
    images: string[];
    reasons: number[];
  }[];
}

export interface SignaturePrintSkuPayload {
  docket_templates_id: number;
  data?: { sku_code: string; custom_text_code: string }[];
}

export interface SignaturePrintRespItem {
  content: any[];
  template: {
    title: string;
    template_type: number;
    docket_type: number;
    spec_type: number;
    images: {
      height: number;
      width: number;
      base64: string;
    };
  };
}
