/**
 * 入库上架权限相关字段，来自
 * https://liaofeng.feishu.cn/sheets/shtcnZwEnu3NxrS22N9Onqwtuzb?sheet=a1f812
 */

/**
 * 入库上架
 */
export const Read = 'wms_inbound_grounding_read';

export const 查看退包上架 = 'wms_inbound_out_on_shelf_read';
