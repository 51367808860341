import type { Page } from '@erp-mobile/types';
import { TrackEvent } from '@erp/lfq';
import axios from 'axios';
import {
  type OutboundTaskRegisterDto,
  OutboundTaskRegisterType,
} from './outbound-task-registration.dto';

export class OutboundTaskService {
  /**
   * 获取发货任务登记
   * 查询全部 all
   */
  async outboundTaskRegister(
    delivery_type: OutboundTaskRegisterType,
  ): Promise<Page<OutboundTaskRegisterDto>> {
    const { data } = await axios.post<Page<OutboundTaskRegisterDto>>(
      `api/outbound/tasks/register`,
      {
        page: 1,
        per_page: 1000,
        delivery_type,
      },
    );
    return data;
  }

  // 发货任务登记
  async registerOutboundTask(task_code: string): Promise<void> {
    await axios.post<void>(
      `api/outbound/tasks/register`,
      {
        task_code,
      },
      {
        trackEvent: TrackEvent.deliveryTaskRegisterScan,
      },
    );
  }
}

export const outboundTaskService = new OutboundTaskService();
