import { useMutation, useQuery } from '@tanstack/react-query';
import type { FinishTaskPayload } from './inventory-task.dto';
import { INVENTORY_TASK_WAREHOUSE } from './inventory-task.key';
import { inventoryTaskService } from './inventory-task.service';

/**
 * 获取盘点任务列表
 */
export const useGetInventoryList = () => {
  return useMutation({
    mutationFn: async (warehouse_id?: number) => {
      return await inventoryTaskService.getTaskList(warehouse_id);
    },
  });
};

/**
 * 完成盘点任务
 */
export const useFinishTask = () => {
  return useMutation({
    mutationFn: async (payload: FinishTaskPayload) => {
      return await inventoryTaskService.finishTask(payload);
    },
  });
};

/**
 * 获取盘点任务用户上次获取的仓库
 */
export const useWarehouse = () => {
  const query = useQuery({
    queryKey: INVENTORY_TASK_WAREHOUSE,
    queryFn: async () => {
      return inventoryTaskService.getWarehouseId();
    },
  });

  return query;
};
