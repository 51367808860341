import { BizError } from './biz-error';

/**
 * 服务端响应的错误体类型
 */
export interface HttpErrorResponseData {
  /**
   * 错误码
   *
   * 用来指定当前的错误类型，目前框架中并未使用到
   *
   * @deprecated 使用 HTTP Code 来区分错误类型
   */
  code: number;
  /**
   * 错误消息
   *
   * 用来指示当前的错误原因
   */
  message: string;
}

/**
 * HTTP 错误基类
 */
export class HttpError extends BizError {
  private _status: number;

  /**
   * 状态码
   */
  get status() {
    return this._status;
  }

  constructor(
    status: number,
    message?: string,
    private options?: ErrorOptions,
  ) {
    super(message, options);
    this._status = status;
  }

  override clone(): HttpError {
    return new HttpError(this.status, this.message, this.options);
  }
}

/**
 * 接口未找到或者资源不存在时产生的错误，此类错误在开发阶段比较常见，一般无需处理
 */
export class NotFoundError extends HttpError {
  constructor(message?: string, options?: ErrorOptions) {
    super(404, message, options);
  }
}

/**
 * 认证失败的错误类
 *
 * 当业务层未对此类错误进行处理时，框架默认会将用户踢出系统页面，并让用户重新登录
 */
export class UnauthenticatedError extends HttpError {
  constructor(message?: string, options?: ErrorOptions) {
    super(401, message, options);
  }
}

/**
 * 权限不足的错误类
 *
 * 当权限不足时框架会自动为用户展示响应的错误界面和提示
 */
export class UnauthorizedError extends HttpError {
  constructor(message?: string, options?: ErrorOptions) {
    super(403, message, options);
  }
}

/**
 * 参数不合法或者参数合法但不符合业务流程时产生的错误，这类错误是在业务代码中需要关心的错误，当未
 * 对该类型的错误进行处理时，框架会统一使用一个消息框展示错误信息
 *
 * 详细说明
 *
 * 1. 参数不合法是指，例如一个登录接口要求用户名和密码必填，但是提交的参数中缺乏相关参数，此时参
 * 数无法通过校验，则会产生参数不合法的错误。由于大多数参数都可以在前端进行相关的合法性校验，所以
 * 此类错误一般在联调阶段产生，在生产环境中较为少见，不过也有些参数必须依赖后端校验
 * 1. 参数合法，但不符合业务流程，还是以登录接口为例，虽然提交了用户名和密码的参数，但是由于用户
 * 名和密码对不上，则此时为业务引起的参数不合法。业务中大多以此类错误为主，前端可以根据实际的错误
 * 信息提示并引导用户进行后续的操作
 */
export class InvalidParamsError extends HttpError {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(status: 400 | 422, message?: string, options?: ErrorOptions) {
    super(status, message, options);
  }
}

/**
 * 其他 HTTP 客户端错误
 *
 * 除了上面列出的可以处理的特殊 HTTP 客户端错误外，其他 HTTP 客户端错误暂不处理，会使用统一的消
 * 息弹窗来提示用户当前错误原因
 */
export class HttpClientError extends HttpError {}

/**
 * HTTP 服务端错误
 *
 * 状态码为 >= 500 的均为服务端错误，此类均为客户端无法处理的未知错误，可以使用一些笼统但友好的
 * 消息提醒用户
 */
export class HttpServerError extends HttpError {}
