import { BooleanNumber } from '../../types/types';

export enum SkuStatus {
  正常销售 = 10,
  商品清仓 = 20,
  等待开发 = 30,
  停止销售 = 40,
}

interface SkuBaseDto {
  /**
   * id
   */
  sku_id: number;
  /**
   * 商品sku编码
   */
  sku_code: string;
  /**
   * 商品名称
   */
  sku_title: string;
  /**
   * 商品状态:10正常销售;20商品清仓;30等待开发;40停止销售
   */
  sku_status: SkuStatus;
  /**
   * 标准重量
   */
  standard_weight: number;
  /**
   * 单品重量
   */
  declare_weight: number;
  /**
   * 封面
   */
  cover_url: string;
  /**
   * 拓展属性编码
   */
  custom_text_code: string;
  /**
   * 拓展属性
   */
  custom_text: string;
}

export interface SkuDto {
  sku_base: SkuBaseDto;
  sku_warehouses: SkuWarehouseDto[];
  /**
   * 使用 sku_base 里面的 sku_id + custom_text_code 拼接的字符串 作为 key
   */
  _id: string;
}

export interface SkuWarehouseDto {
  /**
   * 仓库
   */
  warehouse_title: string;
  /**
   * 仓库id
   */
  warehouse_id: number;
  /**
   * 库存
   */
  stock: number;
  /**
   * 马帮库存
   */
  out_stock: number;
  /**
   * 配货数
   */
  allocated_quantity: number;
  /**
   * 在途数
   */
  sku_transit_quantity: number;
  /**
   * 未发量
   */
  sku_unship_quantity: number;
  /**
   * 最后出库时间
   */
  latest_outbound_at_gmt: string;
  /**
   * 最后入库时间
   */
  latest_inbound_at_gmt: string;
  /**
   * 仓库码
   */
  warehouse_shelf_position_code: string;
  /**
   * 是否默认仓
   */
  is_default: BooleanNumber;
}
export interface SkuParams {
  /**
   * 仓位编码
   */
  warehouse_shelf_position_code?: string;
  /**
   * 商品名称
   */
  sku_title?: string;
  /**
   * 商品sku编码
   */
  sku_code?: string | string[];
  /**
   * 仓库id
   */
  warehouse_id: number;
}
