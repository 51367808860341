import axios from 'axios';
import type {
  FinishInventoryTaskPayload,
  ManualInventorySkuDto,
  ManualInventorySkuParams,
  ManualInventorySkuResponse,
} from './manual-inventory.dto';

class ManualInventoryService {
  tryParseSkuCode(code: string) {
    try {
      return JSON.parse(code);
    } catch (error) {
      return code;
    }
  }
  /**
   * 判断是否根据sku查询，用于做埋点发送
   * @param params 查询参数
   * @param data 查询结果第一条
   * @returns
   */
  isQueryBySku(params: ManualInventorySkuParams, data: ManualInventorySkuDto) {
    // 如果扫码普通sku，则比较sku_code
    if (data.sku_code === params.sku_code) {
      return true;
    }

    // 扫描私人定制sku，比较sku_code和custom_text_code
    const skuCode = this.tryParseSkuCode(params.sku_code);
    if (
      Array.isArray(skuCode) &&
      skuCode.join('_') === [data.sku_code, data.custom_text_code].join('_')
    ) {
      return true;
    }

    return false;
  }

  /**
   * 获取SKU库存信息
   */
  async getSkuStockInfo(params: ManualInventorySkuParams) {
    const { data } = await axios.post<ManualInventorySkuResponse>(
      `api/sku_warehouses/stock/sku`,
      {
        // sku_code或者仓位码，如果是私人定制则为数组形式['sku_code','xxxx']
        sku_code: this.tryParseSkuCode(params.sku_code),
        warehouse_id: params.warehouse_id,
      },
    );
    return data;
  }

  /**
   * 结束盘点
   */
  async finishInventoryTask(payload: FinishInventoryTaskPayload) {
    await axios.post(`/api/inventories/manual/done`, payload);
  }

  /**
   * 获取上次选中的仓库
   */
  async getSelectedWarehouseId() {
    const { data } = await axios.get<{ warehouse_id: number }>(
      `api/warehouse/setting/default`,
    );

    return data.warehouse_id;
  }

  /**
   * 设置选中的仓库
   */
  async setSelectedWarehouseId(id: number) {
    await axios.put(`api/warehouse/setting/default`, {
      warehouse_id: id,
    });
  }
}

export const manualInventoryService = new ManualInventoryService();
