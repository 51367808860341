import { TrackEvent, lfq } from '@erp/lfq';
import axios from 'axios';
import type {
  PasswordCredentialDto,
  TokenDto,
  UserProfile,
  UserRole,
} from './user.dto';

export class UserService {
  private TOKEN_KEY = 'token';
  private PASSWORD_KEY = 'PASSWORD';
  private PHONE_KEY = 'PHONE';

  /**
   * 登录
   * @param dto 用户密码凭证
   * @returns
   */
  async login(dto: PasswordCredentialDto) {
    const trackData = {
      phone: dto.phone,
    };

    try {
      lfq.track(`${TrackEvent.login}RequestStart`, trackData);
      const { data } = await axios.post<TokenDto>('api/auth/login', dto);
      lfq.track(`${TrackEvent.login}RequestSuccess`, trackData);

      return data;
    } catch (error) {
      lfq.track(`${TrackEvent.login}RequestFailed`, trackData);
      throw error;
    }
  }

  /**
   * 获取用户信息
   *
   * @returns 用户信息
   */
  async getUserProfile() {
    const { data } = await axios.post<UserProfile>('api/v2/me');

    // 获取用户信息之后，需要重新 init
    // 追加 user_id
    const trackId = window.trackId;

    if (trackId) {
      lfq.init(trackId, {
        user_id: data.id,
      });
    }

    return data;
  }

  async logout() {
    if (!this.hasToken()) {
      return;
    }

    await Promise.all([
      axios.post('api/auth/logout', void 0, {
        trackEvent: TrackEvent.logout,
      }),
      // 先发退出的请求，再清除 Token，避免退出的请求拿不到 Token
      new Promise<void>((resolve) => {
        setTimeout(() => {
          this.clearToken();
          resolve();
        });
      }),
    ]);
  }

  /**
   * 获取用户下所有角色列表
   */
  async getRoles(): Promise<UserRole[]> {
    const { data } = await axios.get<UserRole[]>('api/roles');
    return data;
  }

  hasToken(): boolean {
    return !!this.getToken();
  }

  setToken(token: string): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY) || '';
  }

  /**
   * 获取 Token 的元信息，去除了签名部分
   * @returns
   */
  getTokenMeta() {
    const token = this.getToken();

    if (!token) {
      return;
    }

    const [header, payload] = token.split('.');

    return [header, payload].join('.');
  }

  setPassword(pwd: string): void {
    localStorage.setItem(this.PASSWORD_KEY, pwd);
  }

  getPassword(): string {
    return localStorage.getItem(this.PASSWORD_KEY) || '';
  }

  setPhone(phone: string): void {
    localStorage.setItem(this.PHONE_KEY, phone);
  }

  getPhone(): string {
    return localStorage.getItem(this.PHONE_KEY) || '';
  }

  clearToken() {
    this.setToken('');
  }
}

export const userService = new UserService();
