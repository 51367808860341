/**
 * 商品信息权限相关字段，来自
 * https://liaofeng.feishu.cn/sheets/shtcnZwEnu3NxrS22N9Onqwtuzb?sheet=a1f812
 */

/**
 * 商品信息查询
 * 控制查看PDA端商品信息查询页
 */
export const Read = 'wms_pda_product_msg_search';
