import { userService } from '@/features/user';
import { snackbar } from '@erp-mobile/components';
import { BizError, UnauthenticatedError } from '@erp-mobile/core';
import { TrackEvent, lfq } from '@erp/lfq';
import { throttle } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useErrorHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * 处理认证异常的时候使用防抖来避免重复提示错误信息
   */
  const handleUnthenticated = useMemo(() => {
    return throttle(
      () => {
        console.error('身份认证失效，请重新登录');
      },
      300,
      {
        trailing: false,
      },
    );
  }, []);

  const handleError = useCallback(
    (error: unknown) => {
      if (error instanceof BizError) {
        if (error instanceof UnauthenticatedError) {
          /**
           * 如果用户已被踢出就不再处理认证失败的错误
           */
          if (location.pathname === '/user/login') {
            return;
          }

          lfq.track(TrackEvent.logout, userService.getTokenMeta());
          userService.clearToken();
          navigate(`/user/login`, { replace: true });
          handleUnthenticated();
          return;
        }

        snackbar.error(error.message);
        return;
      }

      // 除了 BizError 这种自定义错误会使用其自带的 message 展示错误内容外，其他无法识别的错
      // 误均视为未知错误这里仅弹出一个固定的错误提示来告知用户当前的操作出现了异常
      console.error('发生未知的错误');
      snackbar.error('发生未知的错误');
    },
    [handleUnthenticated, location, navigate],
  );

  useEffect(() => {
    const onError = (e: PromiseRejectionEvent) => {
      handleError(e.reason);
    };

    window.addEventListener('unhandledrejection', onError);

    return () => {
      window.removeEventListener('unhandledrejection', onError);
    };
  }, [handleError]);
};
