import { lfq, TrackEvent } from '@erp/lfq';
import { Button } from 'antd-mobile';
import { useAtom } from 'jotai';
import { Iconfont } from '../iconfont';
import { hideKeyboardAtom } from './hide-keyboard.hook';

export const ToggleKeyboardButton = () => {
  const [hideKeyboard, setHideKeyboard] = useAtom(hideKeyboardAtom);

  const onClick = () => {
    setHideKeyboard((prev) => {
      lfq.track(prev ? TrackEvent.hideKeyboard : TrackEvent.showKeyboard);

      return !prev;
    });
  };
  return (
    <Button className="!p-0 leading-none" fill="none" onClick={onClick}>
      <Iconfont
        name={hideKeyboard ? 'keyboardDisable' : 'keyboard'}
        size={20}
      />
    </Button>
  );
};
