import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

/**
 * ERP 版本更新通知
 */
async function getVersion(): Promise<string> {
  if (process.env['NODE_ENV'] === 'development') {
    return 'development';
  }

  try {
    const { data } = await axios.get<{ version: string }>('bff-api/version');
    return data?.version || '';
  } catch (error) {
    console.error(error);
    // 可能存在接口不通的情况，比如上层网关的原因这里
    // 这里即使拿不到也无所谓，直接返回一个空字符串
    return '';
  }
}

export const useVersion = () => {
  return useQuery({
    queryKey: ['erp', 'version'],
    queryFn: async (): Promise<string> => {
      return await getVersion();
    },
    refetchInterval: 60 * 1000,
  });
};

const currentVersion = process.env.NX_APP_VERSION || 'development';

/**
 * 是否有新版本
 */
export const useHasNewVersion = () => {
  const { data: latestVersion } = useVersion();
  return !!latestVersion && currentVersion !== latestVersion;
};
