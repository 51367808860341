import { ErrorBoundary } from '@sentry/react';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ConfigProvider } from 'antd-mobile';
import zhCN from 'antd-mobile/es/locales/zh-CN';
import { RouterProvider } from 'react-router-dom';

import { OverlayProvider } from '@erp-mobile/hooks';
import { SnackbarProvider } from 'notistack';
import { setupAxios, setupSentry, useLoadingChunkError } from './core';
import { routes } from './router';

setupAxios();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 关闭在窗口获取焦点的时候刷新数据
      refetchOnWindowFocus: false,
      // 关闭自动重试
      retry: false,
      networkMode: 'always',
    },
    mutations: {
      networkMode: 'always',
    },
  },
  queryCache: new QueryCache({
    onError(error) {
      Promise.reject(error);
    },
  }),
});

const { router } = setupSentry(routes);

export function App() {
  useLoadingChunkError();

  return (
    <ErrorBoundary>
      <ConfigProvider locale={zhCN}>
        <SnackbarProvider
          autoHideDuration={1_000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          // 移除进场和出场动画
          transitionDuration={{
            enter: 0,
            exit: 0,
          }}
          disableWindowBlurListener
          dense
        />
        <QueryClientProvider client={queryClient}>
          <OverlayProvider>
            <RouterProvider router={router}></RouterProvider>
          </OverlayProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default App;
