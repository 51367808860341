import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { TransferInboundPayload } from './transfer-inbound.dto';
import {
  TRANSFER_INBOUND,
  TRANSFER_INBOUND_TASK,
} from './transfer-inbound.keys';
import { transferInboundService } from './transfer-inbound.service';

/**
 * 获取调拨入库任务
 */
export const useTransferInboundTask = (sign_no: string) => {
  return useQuery({
    queryKey: TRANSFER_INBOUND_TASK(sign_no),
    queryFn: async () => {
      return await transferInboundService.getTask(sign_no);
    },
    enabled: false,
    cacheTime: 0,
  });
};

export const useTransferInbound = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data: TransferInboundPayload[]): Promise<void> => {
      await transferInboundService.inbound(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: TRANSFER_INBOUND,
      });
    },
    onError: () => {
      // 失败也去刷新
      queryClient.invalidateQueries({
        queryKey: TRANSFER_INBOUND,
      });
    },
  });

  return mutation;
};
