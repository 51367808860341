import type { InputRef, TextAreaRef } from 'antd-mobile';
import { useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect, useRef } from 'react';

export const hideKeyboardAtom = atomWithStorage<boolean>(
  'WMS_HIDE_KEYBOARD',
  false,
);

export function useHideKeyboard<T extends InputRef | TextAreaRef = InputRef>() {
  const isHideKeyboard = useAtomValue(hideKeyboardAtom);
  const inputRef = useRef<T>(null);
  const timeoutRef = useRef<number>();

  const hideKeyboardHandler = () => {
    if (isHideKeyboard) {
      hideKeyboard();
    }
  };

  const hideKeyboard = () => {
    inputRef.current?.nativeElement?.setAttribute('readonly', 'readonly');

    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      inputRef.current?.nativeElement?.removeAttribute('readonly');
    }, 50);
  };

  return { inputRef, hideKeyboard, hideKeyboardHandler };
}

/**
 * 延迟 300ms 聚焦
 * Why: Input 组件的 autoFocus 属性无法触发 onFocus 事件，导致隐藏键盘失效
 * @param inputRef 输入框 ref
 */
export const useDelayAutoFocus = (inputRef: React.RefObject<InputRef>) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.nativeElement?.focus();
    }, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [inputRef]);
};

export const useToggleKeyboard = (
  inputRef: React.RefObject<InputRef>,
  autoFocus: boolean,
) => {
  const isHideKeyboard = useAtomValue(hideKeyboardAtom);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (!autoFocus) {
      return;
    }
    const timeout = setTimeout(() => {
      inputRef.current?.nativeElement?.focus();
    }, 300);
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [inputRef, autoFocus]);

  const hideKeyboardHandler = () => {
    if (isHideKeyboard) {
      hideKeyboard();
    }
  };

  const hideKeyboard = () => {
    inputRef.current?.nativeElement?.setAttribute('readonly', 'readonly');

    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      inputRef.current?.nativeElement?.removeAttribute('readonly');
    }, 50);
  };

  return { hideKeyboard, hideKeyboardHandler };
};

/**
 * 专门为原生 input 使用的隐藏键盘 hook
 */
export const useToggleKeyboardForInput = () => {
  const isHideKeyboard = useAtomValue(hideKeyboardAtom);
  const onClickTimeoutRef = useRef<number>();
  const onFocusTimeoutRef = useRef<number>();

  /**
   * 点击时隐藏键盘
   */
  const hideKeyboardOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!isHideKeyboard) {
      return;
    }

    const target = e.currentTarget || e.target;
    target?.setAttribute('readonly', 'readonly');

    if (onClickTimeoutRef.current) {
      window.clearTimeout(onClickTimeoutRef.current);
    }

    onClickTimeoutRef.current = window.setTimeout(() => {
      target?.removeAttribute('readonly');
    }, 50);
  };

  /**
   * 聚焦时隐藏键盘
   */
  const hideKeyboardOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!isHideKeyboard) {
      return;
    }

    e.target?.setAttribute('readonly', 'readonly');

    if (onFocusTimeoutRef.current) {
      window.clearTimeout(onFocusTimeoutRef.current);
    }

    onFocusTimeoutRef.current = window.setTimeout(() => {
      e.target?.removeAttribute('readonly');
    }, 50);
  };

  return {
    hideKeyboardOnClick,
    hideKeyboardOnFocus,
  };
};
