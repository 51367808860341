/**
 * 根据传入的精度格式化 string 类型，例如 '1.234' => '1.23'
 * @param value string 输入值
 * @param precision 精度，默认2
 * @returns
 */
export const formatePrecision = (value: string, precision = 2) => {
  const floatValue = parseFloat(value);

  // 如果转换成功，保留两位小数并返回
  if (!isNaN(floatValue)) {
    const roundedValue = floatValue.toFixed(precision);
    return `${parseFloat(roundedValue)}`; // 将结果解析为浮点数
  } else {
    return ''; // 输入无效
  }
};
