/**
 * 任务盘点权限相关字段，来自
 * https://liaofeng.feishu.cn/sheets/shtcnZwEnu3NxrS22N9Onqwtuzb
 */

/**
 * 任务盘点
 * 包括查看任务盘点列表、获取任务、完成盘点操作，控制WEB端/PDA端
 */
export const 任务盘点 = 'wms_task_check_get';
