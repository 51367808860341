import clsx from 'clsx';

const IconfontMap = {
  diaoboruku: '&#xe630;', // 调拨入库
  diaobochuku: '&#xe631;', // 调拨出库
  tishi: '&#xe61e;', // 提示
  sousuo: '&#xe625;', // 搜索
  'zhankai-shi': '&#xe626;', // 展开
  fanhui: '&#xe627;', // 返回
  tuibaofenjian1: '&#xe624;', // 退包分拣
  renwuxiugaicangwei: '&#xe61b;', // 任务修改仓位
  tuibaoshangjia: '&#xe61c;', // 退包上架
  cangneishangjia: '&#xe61d;', // 仓内上架
  baokuanpeihuo: '&#xe61f;', // 爆款配货
  kuaidiqianshou: '&#xe620;', // 快递签收
  yichangqianshou: '&#xe621;', // 异常签收
  shoudongxiugaicangwei: '&#xe622;', // 手动修改仓位
  tuibaodengji: '&#xe623;', // 退包登记
  duopinxiezuo: '&#xe614;', // 多品协作
  tuihuopeihuo: '&#xe611;', // 退货配货
  zhixiaopinchuku: '&#xe612;', // 直销品出库
  rukushangjia: '&#xe613;', // 入库上架
  rukuqianshou: '&#xe615;', // 入库签收
  duopinpeihuo: '&#xe616;', // 多品配货
  danpinpeihuo: '&#xe617;', // 单品配货
  pandianrenwu: '&#xe618;', // 盘点任务
  tiaobopeihuo: '&#xe619;', // 调拨配货
  gongdan: '&#xe61a;', // 工单
  shangpinxinxi: '&#xe62d;', // 商品信息
  keyboard: '&#xe601;', // 键盘
  keyboardDisable: '&#xe602;', // 禁用键盘
  voice: '&#xe603;', // 语音
  star: '&#xe62b;', // 星星
  shoudongpandian: '&#xe63f;', // 手动盘点
  kuneishangjia: '&#xe643;', // 库内上架
  fahuorenwudengji: '&#xe7c5;', // 发货任务登记
  fuzhi: '&#xe604;', // 复制
  fenjian: '&#xe7ce;', // 分拣
  shangjia: '&#xe7cf;', // 上架
  zhankai: '&#xe605;', // 展开
};

export type IconName = typeof IconfontMap;

export interface IconfontProps {
  className?: string;
  style?: React.CSSProperties;
  name: keyof IconName;
  size?: number;
}

export const Iconfont = ({ className, name, style, size }: IconfontProps) => {
  return (
    <i
      style={{
        ...style,
        fontSize: size || 16,
      }}
      className={clsx('iconfont', className)}
      dangerouslySetInnerHTML={{
        __html: IconfontMap[name],
      }}
    ></i>
  );
};
