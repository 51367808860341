import { useMutation } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import type {
  FinishInventoryTaskPayload,
  ManualInventorySkuParams,
  ManualInventorySkuWithRealStockDto,
} from './manual-inventory.dto';
import { manualInventoryService } from './manual-inventory.service';
import { addListToConfirmData } from './manual-inventory.utils';

/**
 * 获取SKU库存信息
 */
export const useGetSkuStockInfo = () => {
  return useMutation({
    mutationFn: async (params: ManualInventorySkuParams) => {
      return await manualInventoryService.getSkuStockInfo(params);
    },
  });
};

/**
 * 结束盘点
 */
export const useFinishManualInventory = () => {
  return useMutation({
    mutationFn: async (payload: FinishInventoryTaskPayload) => {
      await manualInventoryService.finishInventoryTask(payload);
    },
  });
};

/**
 * 获取选中的仓库id，同时设置选中的仓库id
 * 目前：手动修改仓位、任务修改仓位、手动盘点 共用一套设置
 */
export const useSkuWarehouseId = () => {
  const [warehouseId, setWarehouseId] = useState<number>();

  useEffect(() => {
    manualInventoryService.getSelectedWarehouseId().then((id) => {
      if (id) {
        setWarehouseId(+id);
      }
    });
  }, []);

  /**
   * 保存选中的仓库id
   */
  const saveWarehouseId = async (id: number) => {
    await manualInventoryService.setSelectedWarehouseId(id);
    setWarehouseId(id);
  };

  return {
    warehouseId,
    setWarehouseId,
    saveWarehouseId,
  };
};

export const useConfirmData = () => {
  const [confirmData, setConfirmData] = useState<
    ManualInventorySkuWithRealStockDto[]
  >([]);

  // 返回统计信息
  // totalSkuCode: 已盘点种类
  // totalRealStock: 已盘点数量
  const statistics = useMemo(() => {
    const skuCodeSet = new Set<string>();
    let totalRealStock = 0;

    confirmData.forEach((item) => {
      skuCodeSet.add(item.sku_code);
      totalRealStock += item.real_stock;
    });

    return {
      totalSkuCode: skuCodeSet.size,
      totalRealStock,
    };
  }, [confirmData]);

  // 完成盘点，去新增已完成的盘点数据
  // 同sku_code、同custom_text_code，同position_code视为同一条数据，做下盘点数量累加
  const addConfirmData = (data: ManualInventorySkuWithRealStockDto) => {
    setConfirmData((prev) => {
      const index = prev.findIndex(
        (item) =>
          item.sku_code === data.sku_code &&
          item.custom_text_code === data.custom_text_code &&
          item.position_code === data.position_code,
      );

      if (index === -1) {
        return [...prev, data];
      }

      const newData = { ...prev[index]! };
      // 重复盘点相同的SKU（含订制品），需用最新一次盘点的数据覆盖前次数据
      newData.real_stock = data.real_stock;

      return [...prev.slice(0, index), newData, ...prev.slice(index + 1)];
    });
  };

  const addConfirmDataList = (data: ManualInventorySkuWithRealStockDto[]) => {
    setConfirmData((prev) => {
      return addListToConfirmData(data, prev);
    });
  };

  const clearConfirmData = () => {
    setConfirmData([]);
  };

  return {
    confirmData,
    addConfirmData,
    addConfirmDataList,
    clearConfirmData,
    statistics,
  };
};
