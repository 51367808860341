import type { ManualInventorySkuWithRealStockDto } from './manual-inventory.dto';

const toUniqKey = (item: ManualInventorySkuWithRealStockDto) => {
  return `${item.sku_code}-${item.custom_text_code}-${item.position_code}`;
};

/**
 * 将 list 中的数据添加到 confirmData 中
 * 对应 confirmData 已存在的数据，以 list 中的数据为准
 */
export const addListToConfirmData = (
  list: ManualInventorySkuWithRealStockDto[],
  confirmData: ManualInventorySkuWithRealStockDto[],
) => {
  if (!list || !list.length) {
    return confirmData;
  }

  const newMap = new Map<string, ManualInventorySkuWithRealStockDto>();

  confirmData.forEach((item) => {
    newMap.set(
      toUniqKey(item),

      item,
    );
  });

  list.forEach((item) => {
    const key = toUniqKey(item);
    const existItem = newMap.get(key);

    if (existItem) {
      // 重复盘点相同的SKU（含订制品），需用最新一次盘点的数据覆盖前次数据
      existItem.real_stock = item.real_stock;
    } else {
      newMap.set(key, item);
    }
  });

  return Array.from(newMap.values());
};
