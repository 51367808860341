import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import type { PrimaryKey } from '../../types/types';
import type { PutOnShelvesItem } from './put-on-shelves.dto';
import type {
  ChangePosition,
  FinishedInbound,
  InboundStackingTaskType,
} from './put-on-shelves.entity';
import { putOnShelvesService } from './put-on-shelves.service';

/**
 * 获取上架任务列表
 */
export const usePutOnShelvesList = (type?: InboundStackingTaskType) => {
  const [data, setData] = useState<PutOnShelvesItem[]>();
  const mutation = useMutation({
    mutationFn: async (task_code: string): Promise<PutOnShelvesItem[]> => {
      return await putOnShelvesService.getAll({
        task_code,
        task_type: type,
      });
    },
  });
  const { data: responseData } = mutation;

  useEffect(() => {
    if (responseData) {
      setData(responseData);
    }
  }, [responseData]);

  const updateData = (id: PrimaryKey, itemData: Partial<PutOnShelvesItem>) => {
    const newData = data?.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          ...itemData,
        };
      }
      return item;
    });
    setData(newData);
  };

  return {
    ...mutation,
    data,
    updateData,
  };
};

/**
 * 仓位调整
 */
export const useChangePosition = () => {
  const mutation = useMutation({
    mutationFn: async (data: ChangePosition): Promise<void> => {
      await putOnShelvesService.changePosition(data);
    },
  });

  return mutation;
};

/**
 * 完成上架
 */
export const useFinshied = () => {
  const mutation = useMutation({
    mutationFn: async (data: FinishedInbound): Promise<void> => {
      await putOnShelvesService.finished(data);
    },
  });

  return mutation;
};
