import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import type { SkuParams } from './sku.dto';
import { skuService } from './sku.service';

export const useSkuList = () => {
  return useMutation({
    mutationFn: async (data: SkuParams) => {
      return await skuService.getSkuList(data);
    },
  });
};

/**
 * 获取选中的仓库id，同时设置选中的仓库id
 */
export const useSkuWarehouseId = () => {
  const [warehouseId, setWarehouseId] = useState<number>();

  useEffect(() => {
    skuService.getSelectedWarehouseId().then((id) => {
      if (id) {
        setWarehouseId(id);
      }
    });
  }, []);

  /**
   * 保存选中的仓库id
   */
  const saveWarehouseId = async (id: number) => {
    await skuService.setSelectedWarehouseId(id);
    setWarehouseId(id);
  };

  return {
    warehouseId,
    setWarehouseId,
    saveWarehouseId,
  };
};
