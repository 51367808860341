import { type AxiosInstance, isAxiosError } from 'axios';
import { lfqService } from './lfq.service';
import { TrackEvent } from './track-event';

export class LfqAxiosPlugin {
  constructor(private axios: AxiosInstance) {}

  setup() {
    this.axios.interceptors.request.use((req) => {
      const event = req.trackEvent;

      if (event != null) {
        lfqService.track(`${event}RequestStart`, {
          api: req.url,
          body: this.tryParseJson(req.data),
          searchParams: req.params,
        });
      }

      return req;
    });

    this.axios.interceptors.response.use(
      (res) => {
        const { trackEvent: event, data, params, url } = res.config;

        if (event != null) {
          lfqService.track(`${event}RequestSuccess`, {
            api: url,
            body: this.tryParseJson(data),
            searchParams: params,
          });
        }
        return res;
      },
      (error) => {
        if (isAxiosError(error)) {
          const event = error.config?.trackEvent;

          if (event != null) {
            lfqService.track(`${event}RequestFailed`, {
              api: error.config?.url,
              body: this.tryParseJson(error.config?.data),
              searchParams: error.config?.params,
            });
          }
        }

        return Promise.reject(error);
      },
    );
  }

  private tryParseJson(data: unknown) {
    if (typeof data !== 'string') {
      return;
    }

    try {
      return JSON.parse(data);
    } catch {
      return data;
    }
  }
}

declare module 'axios' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface AxiosRequestConfig<D = any> {
    /**
     * 接口埋点
     *
     * 会自动记录接口的请求参数
     */
    trackEvent?: TrackEvent | string;
  }
}

interface LfqFunction {
  /**
   * 初始化通用统计
   * @param action
   * @param event
   */
  (action: 'init', event: string, data?: { user_id?: string | number }): void;
  /**
   * 上报跟踪的事件
   * @param action
   * @param event
   * @param data
   */
  (action: 'track', event: string, data?: unknown): void;
}

declare global {
  interface Window {
    lfq?: LfqFunction;
  }
}
