import {
  UserKeys,
  type UserProfile,
  UserProfileProvider,
  userService,
} from '@/features/user';
import type { LoaderData } from '@erp-mobile/core';
import { OverlayProvider } from '@erp-mobile/hooks';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { SpinLoading } from 'antd-mobile';
import { type FC, type PropsWithChildren, Suspense, useEffect } from 'react';
import { Await, Outlet, useAsyncValue, useLoaderData } from 'react-router-dom';
import { ErrorFallback } from './layouts';

/**
 * 身份认证拦截守卫
 *
 * 在其子组件加载前会先加载用户信息，用户信息是所有业务逻辑的前提
 *
 * 如果用户信息加载失败则无法进入子页面，身份认证失败会要重新登录，其他错误会展示错误信息并要求用户重试
 */
export const AuthenticationGuard: FC<PropsWithChildren> = () => {
  const data = useLoaderData() as LoaderData;

  return (
    <Suspense fallback={<FullScreenLoading></FullScreenLoading>}>
      <Await resolve={data.user} errorElement={<ErrorFallback />}>
        <InternalRoot></InternalRoot>
      </Await>
    </Suspense>
  );
};

const FullScreenLoading = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center">
      <SpinLoading color="primary"></SpinLoading>
      <div className="text-primary mt-4">正在加载用户数据</div>
    </div>
  );
};

export const InternalRoot = () => {
  const initialUserData = useAsyncValue() as UserProfile;

  const { data } = useQuery({
    queryKey: UserKeys.PROFILE,
    queryFn: async () => {
      const data = await userService.getUserProfile();
      return data;
    },
    initialData: initialUserData,
    // 5 秒内有效，一般来说，已经通过 loader 加载到了用户信息，5秒内不会重复加载
    staleTime: 1000,
  });

  useEffect(() => {
    Sentry.setUser({
      id: data.id,
      // 使用用户代号而不是用户名，避免泄漏用户隐私
      username: data.number,
    });

    return () => {
      Sentry.setUser(null);
    };
  }, [data]);

  return (
    <UserProfileProvider value={data}>
      <OverlayProvider>
        <Outlet></Outlet>
      </OverlayProvider>
    </UserProfileProvider>
  );
};
