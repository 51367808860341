import {
  createContext,
  type FC,
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { OverlayRef } from './overlay-ref';
import { OverlayRefProvider } from './overlay-ref.context';

export const OverlayContext = createContext<OverlayContextValue | null>(null);

/**
 * 弹出层的容器，也是弹出层 DOM 元素所在的根节点
 *
 * 现在组件树中放置该容器，再使用 useOverlay 的 Hook
 */
export const OverlayProvider: FC<PropsWithChildren> = ({ children }) => {
  const [overlaysConfig, setConfig] = useState<Record<string, OverlayConfig>>(
    {},
  );

  const closeOverlay = useCallback<InternalCloseOverlayFunction>(
    (overlayKey) => {
      setConfig((prevConfig) => {
        const config = prevConfig[overlayKey];

        if (!config) {
          return prevConfig;
        }

        return {
          ...prevConfig,
          [overlayKey]: { ...config, isOpen: false },
        };
      });
    },
    [setConfig],
  );

  const openOverlay = useCallback<InternalOpenOverlayFunction>(
    (overlayKey, element) => {
      const overlayRef = new OverlayRef(() => closeOverlay(overlayKey));

      setConfig((prevConfig) => ({
        ...prevConfig,
        [overlayKey]: {
          isOpen: true,
          element,
          overlayRef,
        },
      }));

      return overlayRef;
    },
    [setConfig, closeOverlay],
  );

  const contextValue = useMemo<OverlayContextValue>(
    () => ({
      openOverlay,
      closeOverlay,
      isOpenedOverlay: Object.values(overlaysConfig).some(
        ({ isOpen }) => isOpen,
      ),
    }),
    [openOverlay, closeOverlay, overlaysConfig],
  );

  return (
    <OverlayContext.Provider value={contextValue}>
      {children}
      {Object.keys(overlaysConfig).map((overlayKey) => {
        const config = overlaysConfig[overlayKey];

        if (!config) {
          return null;
        }

        const { element, isOpen, overlayRef } = config;

        if (!isOpen && !overlayRef.closed) {
          overlayRef.close();
        }

        return (
          <OverlayRefProvider key={overlayKey} value={overlayRef}>
            {isOpen && element}
          </OverlayRefProvider>
        );
      })}
    </OverlayContext.Provider>
  );
};

interface InternalCloseOverlayFunction {
  (overlayKey: string): void;
}

interface InternalOpenOverlayFunction {
  (overlayKey: string, element: ReactNode): OverlayRef<unknown>;
}

interface OverlayContextValue {
  openOverlay: InternalOpenOverlayFunction;
  closeOverlay: InternalCloseOverlayFunction;
  isOpenedOverlay: boolean;
}

interface OverlayConfig {
  isOpen: boolean;
  element: ReactNode;
  overlayRef: OverlayRef<unknown>;
}
