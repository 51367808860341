import { enqueueSnackbar } from 'notistack';

export const snackbar = {
  error: (msg: string) => {
    enqueueSnackbar(msg, {
      variant: 'error',
      className: '!bg-gray-900 text-white',
    });
  },
  success: (msg: string) => {
    enqueueSnackbar(msg, {
      variant: 'success',
      className: '!bg-gray-900 text-white',
    });
  },
  info: (msg: string) => {
    enqueueSnackbar(msg, {
      variant: 'info',
      className: '!bg-gray-900 text-white',
    });
  },
};
