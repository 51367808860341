import { type InferType, array, number, object, string } from 'yup';
import type { TransferInboundDto } from './transfer-inbound.dto';

export const transferInboundSchema = object({
  list: array().of(
    object({
      /**
       * 唯一id
       * 为 transfer_batch + id
       */
      rowKey: string().required(),

      transfer_batch: string().required(),

      // ---- 以下字段为：sku相关字段 ----
      id: number().nullable(),
      cost: string().nullable(),
      logistics_number: string().nullable(),
      declare_weight: string().nullable(),
      // 是否合并sku
      is_merge: number().nullable(),
      // 封面
      cover_url: string().nullable(),
      // sku_code
      sku_code: string().nullable(),
      // sku_id
      sku_id: number().nullable(),
      // 目标sku_code
      target_sku_code: string().nullable(),
      // 目标sku_id
      target_sku_id: number().nullable(),
      order_item_id: number().nullable(),
      // 商品名称
      title: string().nullable(),
      // 拓展属性
      custom_text: string().nullable(),
      // 拓展属性编码
      custom_text_code: string().nullable(),
      // 仓库
      warehouse_title: string().nullable(),
      // 仓库id
      warehouse_id: number().nullable(),
      // 仓位
      warehouse_shelf_position_code: string().nullable(),
      // 仓位id
      warehouse_shelf_position_id: number().nullable(),
      // 本次入库量
      inbound_number: number().nullable().required().min(0),
      // 本次破损量
      damaged_number: number().nullable().required().min(0),
      // 已入库数量
      inbound_num: number().nullable(),
      // 总入库量
      total_inbound_num: number().nullable(),
      // 打印数量
      print_number: number().nullable(),
      // 库存数
      stock: number().nullable(),
      // 备注
      remark: string().nullable(),
    }),
  ),
});

export type TransferInboundFormValues = InferType<typeof transferInboundSchema>;

export type TransferInboundFormItemDto =
  Required<TransferInboundFormValues>['list'][number];

export const toTransferInboundFormValues = (data: TransferInboundDto[]) => {
  const result: TransferInboundFormItemDto[] = [];

  for (const item of data) {
    if (!item) {
      continue;
    }

    const { data: skus, ...rest } = item;

    for (const sku of skus) {
      if (!sku) {
        continue;
      }

      const max =
        Number(sku.total_inbound_num ?? 0) - Number(sku.inbound_num ?? 0);

      result.push({
        ...sku,
        rowKey: `${rest.transfer_batch}-${sku.id}`,
        transfer_batch: rest.transfer_batch,
        inbound_number: max,
        damaged_number: 0,
        print_number: max,
        remark: '',
      });
    }
  }

  return result;
};
