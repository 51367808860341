import { useEffect } from 'react';

export const useLoadingChunkError = () => {
  useEffect(() => {
    const onError = (event: ErrorEvent) => {
      if (event?.message?.includes('Loading chunk')) {
        window.location.reload();
      }
    };

    const onUnhandledRejection = (event: PromiseRejectionEvent) => {
      if (event?.reason?.message?.includes('Loading chunk')) {
        window.location.reload();
      }
    };

    window.addEventListener('error', onError);
    window.addEventListener('unhandledrejection', onUnhandledRejection);

    return () => {
      window.removeEventListener('error', onError);
      window.removeEventListener('unhandledrejection', onUnhandledRejection);
    };
  }, []);
};
