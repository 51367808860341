import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  GetSignatureInPayload,
  MutationSignaturePayload,
  SignaturePrintSkuPayload,
} from './signature.dto';
import { SIGNATURE, SIGNATURE_ALL, SIGNATURE_REPORT } from './signature.key';
import signatureService from './signature.service';

export const useSignatureList = (params?: GetSignatureInPayload) => {
  return useQuery({
    queryKey: SIGNATURE_ALL(params),
    queryFn: async () => {
      return signatureService.getSignatureInList(params);
    },
    enabled: false,
    cacheTime: 0,
  });
};

export const useMutationCompleteSignature = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: MutationSignaturePayload): Promise<void> => {
      await signatureService.completeSignature(data);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: SIGNATURE,
      });
    },
  });
};

export const useGetSignatureQualityReport = (params: {
  purchase_number: string;
}) => {
  return useQuery({
    queryKey: SIGNATURE_REPORT(params),
    queryFn: async () => {
      return signatureService.qualityReport(params);
    },
  });
};

export const usePrintTemplate = () => {
  return useMutation({
    mutationFn: async (data: SignaturePrintSkuPayload) => {
      return await signatureService.printSku(data);
    },
  });
};
