import { Toast } from 'antd-mobile';
import { useRef } from 'react';

export function useAsyncLock() {
  const isRunning = useRef(false);
  const timer = useRef<number>();

  const cb = async (asyncFunction: () => Promise<void>) => {
    if (isRunning.current) {
      return;
    }

    isRunning.current = true;
    timer.current = window.setTimeout(() => {
      Toast.show({
        icon: 'loading',
        content: '加载中…',
      });
    }, 300);

    try {
      return await asyncFunction();
    } finally {
      clearTimeout(timer.current);
      Toast.clear();
      isRunning.current = false;
    }
  };

  return cb;
}
