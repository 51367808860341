import { userService } from '@/features/user';
import {
  BizError,
  ClientError,
  HttpClientError,
  type HttpErrorResponseData,
  HttpServerError,
  InvalidParamsError,
  NotFoundError,
  UnauthenticatedError,
  UnauthorizedError,
} from '@erp-mobile/core';
import { LfqAxiosPlugin } from '@erp/lfq';
import axios, { isAxiosError } from 'axios';

export function setupAxios() {
  axios.defaults.baseURL = '/';
  axios.interceptors.request.use(function (config) {
    config.headers.Authorization = userService.getToken();
    return config;
  });

  const lfqPlugin = new LfqAxiosPlugin(axios);
  lfqPlugin.setup();

  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      const normalizedError = normalizeError(error);
      return Promise.reject(normalizedError);
    },
  );
}

/**
 * 对错误进行分类
 * @param error 未知的错误
 * @returns
 */
function normalizeError(error: unknown) {
  const bizError = new BizError('未知的错误', { cause: error });

  if (!isAxiosError<HttpErrorResponseData>(error)) {
    return bizError;
  }

  if (!error.response) {
    return new ClientError(error.message, { cause: error });
  }

  const { status, data, statusText } = error.response;
  const message = data.message ?? statusText;

  /**
   * 401 表示认证失败的错误
   */
  if (status === 401) {
    return new UnauthenticatedError(message ?? statusText, {
      cause: error,
    });
  }

  /**
   * 403 表示权限不足的错误
   */
  if (status === 403) {
    return new UnauthorizedError(message ?? statusText, {
      cause: error,
    });
  }

  /**
   * 404 表示直接或者资源未找到的错误
   */
  if (status === 404) {
    return new NotFoundError(message ?? statusText, {
      cause: error,
    });
  }

  /**
   * 400/422 表示客户端提交的参数不合法导致参数校验失败或者业务流程无法进行下去
   *
   * 具体错误说明详见错误类说明
   */
  if ([400, 422].includes(status)) {
    return new InvalidParamsError(status as 400 | 422, message, {
      cause: error,
    });
  }

  /**
   * 其他客户端错误
   */
  if (status >= 400 && status < 500) {
    return new HttpClientError(status, message, { cause: error });
  }

  /**
   * 服务端错误
   */
  if (status >= 500) {
    return new HttpServerError(status, message, { cause: error });
  }

  return bizError;
}
