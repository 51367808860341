import type { PrimaryKey } from '@erp-mobile/types';
import axios from 'axios';
import type {
  GetTaskParams,
  TransferOutboundSku,
  TransferOutboundTaskDto,
  TransferParams,
} from './transfer-outbound.dto';

/**
 * 调拨出库
 */
export class TransferOutboundService {
  formatSkuCode(sku: TransferOutboundSku) {
    let skuCode = [sku.sku_code];
    if (sku.custom_text_code) {
      skuCode = [sku.sku_code, sku.custom_text_code];
    }
    return JSON.stringify(skuCode);
  }
  /**
   * 获取任务
   */
  async getTask(params: GetTaskParams) {
    const { data } = await axios.post<TransferOutboundTaskDto>(
      'api/outbound/transfer_tasks',
      params,
    );
    return data;
  }

  /**
   * 获取任务
   */
  async getTaskInfo() {
    const { data } = await axios.get<TransferOutboundTaskDto>(
      'api/outbound/transfer_tasks',
    );
    return data;
  }

  /**
   * 调拨出库
   */
  async transfer({ id, ...params }: TransferParams) {
    const { data } = await axios.post(
      `api/outbound/transfer_tasks/${id}`,
      params,
    );
    return data;
  }

  /**
   * 扫码sku可能是json字符串，需要做parse后返回数组
   */
  parseSku(sku: string): [skuCode: string, customTextCode?: string] {
    try {
      const res = JSON.parse(sku);
      if (Array.isArray(res)) {
        const [skuCode, customTextCode] = res;
        return [skuCode, customTextCode];
      }

      return [sku];
    } catch (error) {
      return [sku];
    }
  }

  /**
   * 异常出库
   */
  async abnormal(
    id: PrimaryKey,
    params: {
      sku_id: PrimaryKey;
      custom_text_code: string;
    },
  ) {
    await axios.post(`api/outbound/transfer_tasks/${id}/sku/abnormal`, params);
  }
}

export const transferOutboundService = new TransferOutboundService();
