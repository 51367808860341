export enum OutboundTaskRegisterStatus {
  待分拣 = 20,
  分拣中 = 21,
  待发货 = 30,
  发货中 = 31,
}

export enum OutboundTaskRegisterType {
  全部 = 0,
  单品发货 = 1,
  多品发货 = 2,
}

export interface OutboundTaskRegisterDto {
  /**
   * 任务码
   */
  task_code: string;
  /**
   * 发货状态
   */
  delivery_status_title: string;
  /**
   * 发货类型
   */
  delivery_type_title: string;
  /**
   * 仓库名称
   */
  warehouse_title: string;
  /**
   * 配货员
   */
  allocate_user_name: string;
  /**
   * 分拣员
   */
  picking_user_name: string;
  /**
   * 发货员
   */
  delivery_user_name: string;
}
