/**
 * 单品/多品配货权限相关字段，来自
 * https://liaofeng.feishu.cn/sheets/shtcnZwEnu3NxrS22N9Onqwtuzb?sheet=a1f812
 */

/**
 * 单品配货
 */
export const SingleRead = 'wms_one_delivery_match';

/**
 * 多品配货
 */
export const MultiRead = 'wms_many_delivery_match';

/**
 * 爆款配货
 */
export const 爆款配货 = 'wms_hot_inbound_match';
