import axios from 'axios';
import type { FinishTaskPayload, InventoryTaskDto } from './inventory-task.dto';

class InventoryTaskService {
  /**
   * 扫码sku可能是json字符串，需要做parse后取第一项
   * @param sku sku🐴
   * @returns 转换后的 sku 码
   * @example "[\'sku123\', \'xxx\']" => ["sku123", "xxx"]
   */
  parseSku2Array(sku: string) {
    try {
      const res = JSON.parse(sku);
      return Array.isArray(res) ? res : [res];
    } catch (error) {
      return [sku];
    }
  }

  async getTaskList(
    warehouse_id?: number,
  ): Promise<InventoryTaskDto | undefined> {
    if (!warehouse_id) {
      return void 0;
    }

    const { data } = await axios.get<InventoryTaskDto>('api/inventories_task', {
      params: { warehouse_id },
    });
    return data;
  }

  async finishTask(payload: FinishTaskPayload): Promise<void> {
    await axios.post(`api/inventories_task/done`, payload);
  }

  /**
   * 获取盘点任务用户上次获取的仓库
   * @returns 仓库id
   */
  async getWarehouseId(): Promise<string | undefined> {
    const { data } = await axios.get<string[]>(
      `api/inventories_task/default/warehouse`,
    );
    return data?.[0] ?? '';
  }
}

export const inventoryTaskService = new InventoryTaskService();
