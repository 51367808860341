import { TrackEvent } from '@erp/lfq';
import axios from 'axios';
import type {
  GetSkuPositionPayload,
  WarehouseStackingGetBestPathPayload,
  WarehouseStackingGetBestPathResponse,
  WarehouseStackingPayload,
  WarehouseStackingResponse,
  WarehouseStackingSkuDto,
} from './warehouse-stacking.dto';

/**
 * 库内上架
 */
export class WarehouseStackingService {
  tryParseSkuCode = (code: string) => {
    try {
      return JSON.parse(code);
    } catch (error) {
      return code;
    }
  };
  /**
   * 获取SKU
   */
  async getSku(payload: GetSkuPositionPayload) {
    const { data } = await axios.post<WarehouseStackingSkuDto>(
      'api/skus/warehouses/position',
      {
        ...payload,
        sku_code: this.tryParseSkuCode(payload.sku_code),
      },
    );
    return data;
  }

  /**
   * 获取最佳路径
   */
  async getBestPath(payload: WarehouseStackingGetBestPathPayload) {
    const { data } = await axios.post<WarehouseStackingGetBestPathResponse>(
      'api/warehouse/skus/best_path',
      payload,
    );
    return data;
  }

  /**
   * 库内上架
   */
  async stacking(payload: WarehouseStackingPayload) {
    const { data } = await axios.put<WarehouseStackingResponse>(
      'api/warehouse/skus/inner_on_shelf',
      payload,
      { trackEvent: TrackEvent.warehouseStackingFinish },
    );
    return data;
  }

  /**
   * 获取选中的仓库id
   * 单品、多品、爆款配货、调拨入库、库内上架模块
   */
  async getSelectedWarehouseId() {
    const { data } = await axios.get<{ warehouse_id: number }>(
      `api/outbound/warehouse/latest_delivery`,
    );

    return data.warehouse_id;
  }

  /**
   * 设置选中的仓库id
   * 单品、多品、爆款配货、调拨入库、库内上架模块
   */
  async setSelectedWarehouseId(id: number) {
    await axios.put(`api/outbound/warehouse/latest_delivery`, {
      warehouse_id: id,
    });
  }
}

export const warehouseStackingService = new WarehouseStackingService();
