import axios from 'axios';
import { isArray } from 'lodash-es';
import type {
  WarehouseOptionItem,
  WarehouseOptionParams,
  WarehousePositionBody,
  WarehousePositionItemDto,
  WarehousePositionPayload,
} from './modify-position.dto';

class ModifyPositionService {
  /**
   * 将用户扫描输入转换为json对象，如果是普通商品则返回原值
   * why: 获取仓位信息接口 sku_code 参数：普通商品是字符串，私人定制商品是数组
   * @param sku 用户扫描输入
   * @returns sku 或 sku 带拓展属性数组
   */
  private tryParseSkuToJson(sku?: string) {
    if (!sku) return sku;

    try {
      return JSON.parse(sku);
    } catch (error) {
      return sku;
    }
  }

  async getWarehousePositions(
    params: WarehousePositionPayload,
  ): Promise<WarehousePositionItemDto[]> {
    const { data } = await axios.post('api/skus/warehouses/position', {
      ...params,
      sku_code: this.tryParseSkuToJson(params.sku_code),
    });
    return [data];
  }

  async putWarehousePosition(params: WarehousePositionBody) {
    await axios.put('api/skus/warehouses/position', {
      ...params,
      sku_code: this.tryParseSkuToJson(params.sku_code),
    });
  }

  /**
   * 获取仓库option
   * @param is_data_permissions 默认1:不按数据权限展示   2:按数据权限展示
   */
  async getWarehouseOptions(
    params: WarehouseOptionParams,
  ): Promise<WarehouseOptionItem[]> {
    const { data } = await axios.get<WarehouseOptionItem[]>(
      `api/warehouses/options`,
      {
        params,
      },
    );

    return data;
  }

  /**
   * 扫码sku可能是json字符串，需要做parse后取第一项
   * @param sku sku🐴
   * @returns 转换后的 sku 码
   * @example ['sku123', 'xxx'] => 'sku123'
   */
  parseSku(sku: string) {
    try {
      const res = JSON.parse(sku);
      return isArray(res) ? res[0] : res;
    } catch (error) {
      return sku;
    }
  }
}

const modifyPositionService = new ModifyPositionService();

export default modifyPositionService;
