import { useMyAuthorities } from '@/features/user';
import type { RouteConfigHandle } from '@erp-mobile/core';

import { useSystemSettings } from '@/features/system-settings';
import { ErrorBlock } from 'antd-mobile';
import { useMemo } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { checkCustomAuthority } from './custom-authorization';

/**
 * 权限校验
 * @returns ReactNode
 */
export default function Authorization() {
  const authorities = useMyAuthorities();
  const { data: systemSettings } = useSystemSettings();
  const matchRoutes = useMatches();

  const authorized = useMemo(() => {
    for (const route of matchRoutes) {
      const handle = route.handle as RouteConfigHandle | undefined;

      if (!handle?.authorities?.length) {
        continue;
      }

      const hasPermission = handle.authorities.every((authority) =>
        authorities.some((item) => item === authority),
      );

      const hasCustomPermission = checkCustomAuthority(
        handle.customAuthority,
        systemSettings,
      );

      if (hasPermission && hasCustomPermission) {
        continue;
      }

      return false;
    }

    return true;
  }, [matchRoutes, authorities, systemSettings]);

  if (!authorized) {
    return (
      <ErrorBlock
        fullPage
        status="busy"
        title="权限不足"
        description="您暂无该页面查看的权限，请联系管理员"
      ></ErrorBlock>
    );
  }

  return <Outlet></Outlet>;
}
