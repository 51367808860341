import type { PrimaryKey } from '@erp-mobile/types';
import { TrackEvent } from '@erp/lfq';
import axios from 'axios';
import { isArray } from 'lodash-es';
import type {
  AllocateOrder,
  DistributionTaskData,
  OrderInfoItem,
} from './distribution-task.dto';
import type { Type } from './distribution-task.entity';

export class DistributionTaskService {
  /**
   * 获取配货任务
   */
  async getSingleTask(
    type: Type,
    warehouse_id: PrimaryKey,
  ): Promise<DistributionTaskData | { message: string }> {
    const { data } = await axios.post<
      DistributionTaskData | { message: string }
    >(
      type === 'single'
        ? 'api/outbound/delivery_tasks/single/allocate'
        : 'api/outbound/delivery_tasks/multi/allocate',
      {
        warehouse_id,
      },
      {
        trackEvent:
          type === 'single'
            ? TrackEvent.singlePickingTaskQuery
            : TrackEvent.multiPickingTaskQuery,
      },
    );

    return data;
  }

  /**
   * 获取配货中的任务详情
   */
  async getSingleTaskDetail(type: Type): Promise<DistributionTaskData> {
    const { data } = await axios.get<DistributionTaskData>(
      type === 'single'
        ? 'api/outbound/delivery_tasks/single/allocate'
        : 'api/outbound/delivery_tasks/multi/allocate',
    );

    return data;
  }

  /**
   * 单品/多品绑定任务码
   */
  async bindCode({
    id,
    type,
    ...data
  }: {
    id: PrimaryKey;
    task_code: string;
    type: Type;
  }): Promise<void> {
    const _type = type === 'single' ? 'single' : 'multi';
    await axios.put(`api/outbound/delivery_tasks/${id}/${_type}/bind`, data);

    return;
  }

  /**
   * 配货任务执行
   */
  async singleExecute({
    id,
    type,
    ...data
  }: {
    id: PrimaryKey;
    quantity: number;
    sku_code: string;
    type: Type;
  }): Promise<void> {
    await axios.post(
      type === 'single'
        ? `api/outbound/delivery_tasks/${id}/single/allocate`
        : `api/outbound/delivery_tasks/${id}/multi/allocate`,
      data,
    );

    return;
  }

  /**
   * 获取订单列表
   */
  async getSingleOrderInfo({
    id,
    type,
    ...query
  }: {
    id: PrimaryKey;
    sku_code: string;
    custom_text_code: string;
    type: Type;
  }): Promise<OrderInfoItem[]> {
    const _type = type === 'single' ? 'single' : 'multi';
    const { data } = await axios.get<OrderInfoItem[]>(
      `api/outbound/delivery_tasks/${id}/${_type}/orders`,
      { params: query },
    );

    return data;
  }

  /**
   * 配货异常
   */
  async singleAbnormal({
    id,
    type,
    ...query
  }: {
    id: PrimaryKey;
    sku_code: string;
    type: Type;
  }): Promise<{ message: string }> {
    const { data } = await axios.post(
      type === 'single'
        ? `api/outbound/delivery_tasks/${id}/single/allocate/abnormal`
        : `api/outbound/delivery_tasks/${id}/multi/allocate/abnormal`,
      query,
      {
        trackEvent:
          type === 'single'
            ? TrackEvent.singlePickingException
            : TrackEvent.multiPickingException,
      },
    );
    return data;
  }

  /**
   * 配货任务完成
   */
  async singleTaskComplete({
    id,
    type,
  }: {
    id: PrimaryKey;
    type: Type;
  }): Promise<void> {
    await axios.put<void>(
      type === 'single'
        ? `api/outbound/delivery_tasks/${id}/single/allocate/complete`
        : `api/outbound/delivery_tasks/${id}/multi/allocate/complete`,
    );
  }

  /**
   * 配货单打印
   */
  async printOrder({
    id,
    type,
  }: {
    id: PrimaryKey;
    type: Type;
  }): Promise<AllocateOrder> {
    const { data } = await axios.get<AllocateOrder>(
      `api/outbound/delivery_tasks/${id}/${
        type === 'single' ? 'single' : 'multi'
      }/allocate/print`,
    );

    return data;
  }

  /**
   * 扫码sku可能是json字符串，需要做parse后返回数组
   */
  parseSku(sku: string) {
    try {
      const res = JSON.parse(sku);
      return isArray(res) ? res : [res];
    } catch (error) {
      return [sku];
    }
  }

  async fixQuantity(id: PrimaryKey, sku_qrcode: string) {
    await axios.get(`api/outbound/delivery_tasks/${id}/allocate/correct`, {
      params: {
        sku_qrcode,
      },
    });
  }
}

export const distributionTaskService = new DistributionTaskService();
