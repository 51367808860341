import axios from 'axios';
import type { PutOnShelvesItem } from './put-on-shelves.dto';
import {
  type ChangePosition,
  type FinishedInbound,
  InboundStackingTaskType,
} from './put-on-shelves.entity';

export class PutOnShelvesService {
  /**
   * 获取上架任务列表
   */
  async getAll(params: {
    task_code?: string;
    task_type?: InboundStackingTaskType;
  }): Promise<PutOnShelvesItem[]> {
    const { data } = await axios.get<PutOnShelvesItem[]>(
      'api/inbound/stacking',
      {
        params,
      },
    );

    return data;
  }

  /**
   * 仓位调整
   */
  async changePosition(data: ChangePosition): Promise<void> {
    await axios.put('api/inbound/warehouse', data);
  }

  /**
   * 完成上架
   */
  async finished({ id, ...data }: FinishedInbound): Promise<void> {
    await axios.post(`api/inbound/${id}/stacking`, data);
  }

  /**
   * 获取未完成的任务
   */
  async getUnfinishTaskCode(task_type: InboundStackingTaskType) {
    const { data } = await axios.get<{ task_code: string }>(
      `api/inbound/tasking`,
      {
        params: {
          task_type,
        },
      },
    );
    return data?.task_code ?? '';
  }
}

export const putOnShelvesService = new PutOnShelvesService();
