import type { PrimaryKey } from '@erp-mobile/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  GetTaskParams,
  TransferOutboundTaskDto,
  TransferParams,
} from './transfer-outbound.dto';
import { TASK_INFO } from './transfer-outbound.keys';
import { transferOutboundService } from './transfer-outbound.service';

/**
 * 进入页面，获取调拨出库任务详情
 */
export const useGetTaskInfo = () => {
  const query = useQuery({
    queryKey: TASK_INFO,
    queryFn: async () => {
      return await transferOutboundService.getTaskInfo();
    },
  });
  return query;
};

/**
 * 点击按钮，获取调拨出库任务
 */
export const usePostTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      params: GetTaskParams,
    ): Promise<TransferOutboundTaskDto> => {
      return await transferOutboundService.getTask(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: TASK_INFO,
      });
    },
  });
};

/**
 * 调拨出库
 */
export const useTransferOutbound = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: TransferParams) => {
      return await transferOutboundService.transfer(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: TASK_INFO,
      });
    },
  });
};

/**
 * 异常出库
 */
export const useAbnormalTransferOutbound = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: {
      id: PrimaryKey;
      skuID: PrimaryKey;
      customTextCode: string;
    }) => {
      return await transferOutboundService.abnormal(params.id, {
        sku_id: params.skuID,
        custom_text_code: params.customTextCode,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: TASK_INFO,
      });
    },
  });
};
