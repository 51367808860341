import { Button, ErrorBlock } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <ErrorBlock
      fullPage
      status="empty"
      title="404"
      description="对不起，您访问的页面不存在"
    >
      <Button
        color="primary"
        onClick={() => {
          navigate('/');
        }}
      >
        回到首页
      </Button>
    </ErrorBlock>
  );
}
