import { Button } from 'antd-mobile';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { ToggleKeyboardButton } from './hide-keyboard';
import { Iconfont } from './iconfont';
import S from './page-header.module.less';

interface PageHeaderProps {
  title: string;
  onBack?: () => void;
  /**
   * 是否显示切换隐藏键盘按钮
   */
  toggleKeyboard?: boolean;
}

export const PageHeader = (props: PageHeaderProps) => {
  const navigate = useNavigate();

  // 默认回到首页
  const handleClickBack = () => {
    if (props.onBack) {
      props.onBack();
    } else {
      navigate('/');
    }
  };

  return (
    <div className="relative flex items-center justify-center px-4 py-2">
      <Button
        className={clsx(S['override'], 'left-5')}
        fill="none"
        onClick={handleClickBack}
      >
        <Iconfont name="fanhui" size={22} />
      </Button>
      <div className="max-w-[60%] truncate text-center text-lg font-bold leading-relaxed text-zinc-800">
        {props.title}
      </div>
      {props.toggleKeyboard ? (
        <div className={clsx(S['override'], 'right-5 space-x-2')}>
          <ToggleKeyboardButton />
        </div>
      ) : null}
    </div>
  );
};
