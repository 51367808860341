/**
 * 任务盘点权限相关字段，来自
 * https://liaofeng.feishu.cn/sheets/shtcnZwEnu3NxrS22N9Onqwtuzb
 */

/**
 * 手动盘点
 * 控制查看PDA手动盘点页面
 */
export const 手动盘点 = 'wms_manual_inventory';
