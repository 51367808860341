import { Button, ErrorBlock } from 'antd-mobile';
import { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    if (error instanceof Error) {
      const isChunkLoadError = error?.message?.includes('Loading chunk');
      if (isChunkLoadError) {
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <ErrorBlock fullPage status="empty" title="发生未知错误">
      <Button
        color="primary"
        onClick={() => {
          navigate('/');
        }}
      >
        回到首页
      </Button>
    </ErrorBlock>
  );
}
