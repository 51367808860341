import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { OutboundTaskRegisterType } from './outbound-task-registration.dto';
import { REGISTER, REGISTER_LIST } from './outbound-task-registration.key';
import { outboundTaskService } from './outbound-task-registration.service';

export const useRegisteOutboundTask = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (code: string) => {
      return await outboundTaskService.registerOutboundTask(code);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: REGISTER,
      });
    },
  });
};

// 获取发货任务登记
export const useOutboundTaskRegister = (
  delivery_type: OutboundTaskRegisterType,
) => {
  return useQuery({
    queryKey: REGISTER_LIST(delivery_type),
    queryFn: async () => {
      return outboundTaskService.outboundTaskRegister(delivery_type);
    },
  });
};

// 获取全部发货任务登记
export const useAllOutboundTaskRegister = (type: OutboundTaskRegisterType) => {
  const all = useOutboundTaskRegister(OutboundTaskRegisterType.全部);
  const single = useOutboundTaskRegister(OutboundTaskRegisterType.单品发货);
  const multi = useOutboundTaskRegister(OutboundTaskRegisterType.多品发货);

  useEffect(() => {
    switch (type) {
      case OutboundTaskRegisterType.全部:
        all.refetch();
        break;
      case OutboundTaskRegisterType.单品发货:
        single.refetch();
        break;
      case OutboundTaskRegisterType.多品发货:
        multi.refetch();
        break;
    }
  }, [type]);

  return {
    allData: all.data,
    data:
      type === OutboundTaskRegisterType.全部
        ? all
        : type === OutboundTaskRegisterType.单品发货
        ? single
        : multi,
    tabTitles: {
      [OutboundTaskRegisterType.全部]: all.data?.meta?.total
        ? `全部(${all.data?.meta?.total})`
        : '全部',
      [OutboundTaskRegisterType.单品发货]: single.data?.meta?.total
        ? `单品(${single.data?.meta?.total})`
        : '单品',
      [OutboundTaskRegisterType.多品发货]: multi.data?.meta?.total
        ? `多品(${multi.data?.meta?.total})`
        : '多品',
    },
  };
};
