import {
  BooleanNumber,
  type Page,
  type Pagination,
  type PrimaryKey,
} from '@erp-mobile/types';
import axios from 'axios';

import type {
  CountInfo,
  CountInfoTotal,
  LogItem,
  Refresh,
  WarehouseGroup,
  WarehouseItem,
  WarehouseOptionItem,
  WarehouseOptionParams,
  WarehousePositions,
} from './warehouse-setting.dto';
import type {
  CreateGroup,
  CreateWarehouse,
  QueryParams,
} from './warehouse-setting.entity';

export class WarehouseSettingService {
  /**
   * 获取仓库分组列表
   */
  async getAllGroup(): Promise<WarehouseGroup[]> {
    const { data } = await axios.get<WarehouseGroup[]>(
      'api/warehouse_groups/options',
    );

    return data;
  }

  /**
   * 批量修改仓库分组
   */
  async updateGroup({
    id,
    warehouse_group_id,
  }: {
    id: number[];
    warehouse_group_id: PrimaryKey;
  }): Promise<void> {
    await axios.put(`api/warehouses/group`, { id, warehouse_group_id });

    return;
  }

  /**
   * 创建仓库分组
   * @param data 分组信息
   */
  async createGroup(data: CreateGroup): Promise<void> {
    await axios.post('api/warehouse_groups', data);

    return;
  }

  /**
   * 删除仓库分组
   */
  async removeGroup(id: PrimaryKey): Promise<void> {
    await axios.delete(`api/warehouse_groups/${id}`);

    return;
  }

  /**
   * 获取仓库列表
   */
  async getAll(
    queryParams: QueryParams & Pagination,
  ): Promise<Page<WarehouseItem>> {
    const { pageNumber, pageSize, ...query } = queryParams;
    const { data } = await axios.get<Page<WarehouseItem>>('api/warehouses', {
      params: {
        ...query,
        per_page: pageSize,
        page: pageNumber,
      },
    });

    return data;
  }

  /**
   * 手动更新数据
   */
  async refresh({
    is_refresh,
    title,
  }: {
    is_refresh: BooleanNumber;
    title: string;
  }): Promise<Refresh> {
    const { data } = await axios.get<Refresh>('api/warehouses/refresh', {
      params: { is_refresh, title },
    });

    return data;
  }

  /**
   * 创建仓库
   * @param data 仓库信息
   */
  async createWarehouse(data: CreateWarehouse): Promise<void> {
    await axios.post('api/warehouses', data);

    return;
  }

  /**
   * 更新仓库信息
   * @param data 仓库信息
   */
  async updateWarehouse({
    id,
    ...data
  }: CreateWarehouse & { id: PrimaryKey }): Promise<void> {
    await axios.put(`api/warehouses/${id}`, data);

    return;
  }

  /**
   * 库存价值明细
   */
  async getValueList({
    warehouse_id,
    ...queryParams
  }: Pagination & { warehouse_id: PrimaryKey }): Promise<Page<CountInfo>> {
    const { pageNumber, pageSize } = queryParams;
    const { data } = await axios.get<Page<CountInfo>>(
      `api/warehouses/skus/info/`,
      {
        params: {
          per_page: pageSize,
          page: pageNumber,
          warehouse_id,
        },
      },
    );

    return data;
  }

  /**
   * 根据库存ID获取库存总量总价值等数据
   */
  async getValueTotla(warehouse_id: PrimaryKey): Promise<CountInfoTotal> {
    const { data } = await axios.get<CountInfoTotal>(
      `api/skus/warehouses/${warehouse_id}/total`,
    );

    return data;
  }

  /**
   * 获取仓库详情
   */
  async getInfo(id?: PrimaryKey): Promise<WarehouseItem> {
    const { data } = await axios.get<WarehouseItem>(`api/warehouses/${id}`);

    return data;
  }

  /**
   * 仓库停用/启用
   */
  async updateStatus({
    id,
    is_publish,
  }: {
    id: PrimaryKey;
    is_publish: BooleanNumber;
  }): Promise<void> {
    await axios.put(`api/warehouses/${id}/status`, { is_publish });

    return;
  }

  /**
   * 设置默认仓库
   */
  async setDefault(id: PrimaryKey): Promise<void> {
    await axios.put(`api/warehouses/${id}/default`);

    return;
  }

  /**
   * 获取操作日志
   */
  async getLog({
    id,
    ...queryParams
  }: { id: PrimaryKey } & Pagination): Promise<Page<LogItem>> {
    const { pageNumber, pageSize } = queryParams;
    const { data } = await axios.get<Page<LogItem>>(
      `api/warehouses/log/${id}`,
      {
        params: {
          per_page: pageSize,
          page: pageNumber,
        },
      },
    );

    return data;
  }

  /**
   * 获取仓库option
   * @param is_data_permissions 默认1:不按数据权限展示   2:按数据权限展示
   */
  async getWarehouseOptions(
    params: WarehouseOptionParams,
  ): Promise<WarehouseOptionItem[]> {
    const { data } = await axios.get<WarehouseOptionItem[]>(
      `api/warehouses/options`,
      {
        params,
      },
    );

    return data;
  }

  /**
   * 根据仓库获取仓位
   */
  async getWarehousePositions(
    id?: PrimaryKey,
    value?: string,
  ): Promise<WarehousePositions[]> {
    const { data } = await axios.get<WarehousePositions[]>(
      `api/warehouses/positions/${id}`,
      {
        params: {
          title: value,
        },
      },
    );

    return data;
  }
}

export const warehouseSettingService = new WarehouseSettingService();
