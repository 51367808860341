import type { WarehousePositionPayload } from './modify-position.dto';

export const POSITION_INFO = (payload: WarehousePositionPayload) => [
  'WAREHOUSE_POSITION_INFO',
  { ...payload },
];

// 为了记录用户上次选中的仓库为默认项，需要保存仓库 id 到 localStorage 中
// 手动修改仓位 - 仓库 id
export const WAREHOUSE_ID_KEY = 'WAREHOUSE_ID_KEY';
