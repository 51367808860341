import type { PrimaryKey } from '@erp-mobile/types';
import type { Type } from './distribution-task.entity';

/**
 * 单品配货任务的查询 Key
 */
export const SINGLELIST = ['SINGLE_DISTRIBUTION_TASK'] as const;

export const ALLSINGLE = [...SINGLELIST, 'ALL'];

/**
 * 单品配货任务订单详情的查询 Key
 */

export const SINGLE_ORDER = (
  id: PrimaryKey,
  sku_code: string,
  custom_text: string,
) => [...SINGLELIST, { id, sku_code, custom_text }];

/**
 * 多品配货任务的查询 Key
 */
export const MANYLIST = ['MANY_DISTRIBUTION_TASK'] as const;

export const ALLMANY = [...MANYLIST, 'ALL'];

/**
 * 配货单查询 key
 */
export const ALLOCATE_ORDER = ['ALLOCATE_ORDER'];
export const PRINT = (id: PrimaryKey, type: Type) =>
  [...ALLOCATE_ORDER, id, type] as const;
