import type { FieldArrayWithId } from 'react-hook-form';
import { BooleanNumber, type PrimaryKey } from '../../types/types';

export interface WarehouseStackingSkuDto {
  id: PrimaryKey;
  /**
   * SKU 封面
   */
  images: string;
  /**
   * SKU 编码
   */
  sku_code: string;
  /**
   * SKU 名称
   */
  sku_title: string;
  /**
   * 仓库名称
   */
  warehouse_title: string;
  /**
   * 仓位码
   */
  warehouse_position_code: string;
  /**
   * 仓位id
   */
  warehouse_shelf_position_id: number;
  /**
   * 库存
   */
  stock: number;
  /**
   * 拓展属性
   */
  custom_text: string;
  /**
   * 拓展属性编码
   */
  custom_text_code: string;
  /**
   * sku id
   */
  sku_id: number;
  /**
   * 是否私人定制
   */
  is_custom: BooleanNumber;
  /**
   * 货架码
   */
  warehouse_shelf_code: string;
}

export interface GetSkuPositionPayload {
  sku_code: string;
  warehouse_id: PrimaryKey;
  type: string;
}

export interface WarehouseStackingFormValues {
  list: Array<{
    sku: WarehouseStackingSkuDto;
    count: number;
  }>;
}

export type WarehouseStackingField = FieldArrayWithId<
  WarehouseStackingFormValues,
  'list',
  'id'
>;

// 最佳路径入参
export interface WarehouseStackingGetBestPathPayload {
  warehouse_id: PrimaryKey;
  skus: Array<WarehouseStackingGetBestPathPayloadSku>;
}

export interface WarehouseStackingGetBestPathPayloadSku
  extends WarehouseStackingSkuDto {
  count: number;
}

// 最佳路径返回（同入参）
export type WarehouseStackingGetBestPathResponse =
  WarehouseStackingGetBestPathPayload;

export interface WarehouseStackingPayload {
  warehouse_id: PrimaryKey;
  skus: WarehouseStackingPayloadSku[];
}

export interface WarehouseStackingResponse {
  diff_skus: Array<{
    /**
     * 拓展属性
     */
    custom_text: string;
    /**
     * 拓展属性编码
     */
    custom_text_code: string;
    /**
     * 仓位编码
     */
    positions_code: string;
    /**
     * 仓位id
     */
    positions_id: number;
    /**
     * sku编码
     */
    sku_code: string;
    /**
     * sku id
     */
    sku_id: number;
    /**
     * 库存
     */
    stock: number;
  }>;
}

export interface WarehouseStackingPayloadSku {
  /**
   * sku id
   */
  sku_id: PrimaryKey;
  /**
   * 拓展属性
   */
  custom_text: string;
  /**
   * 拓展属性编码
   */
  custom_text_code: string;
  /**
   * 上架数量
   */
  num: number;
  /**
   * 仓位编码
   */
  positions_code: string;
  /**
   * 仓位id
   */
  positions_id: PrimaryKey;
  /**
   * sku编码
   */
  sku_code: string;
  /**
   * 库存
   */
  stock: number;
}

export enum WarehouseStackingType {
  分拣 = 1,
  上架 = 2,
}
